<template>
    <div class="animated fadeIn">
        <section class="hero is-medium has-border-bottom-gray">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-vcentered">
                        <div class="column">
                            <div class="columns is-centered has-padding-top-2x-mobile">
                                <div class="column is-narrow">
                                    <h1 class="subtitle is-size-3 has-text-grey-light has-text-centered-mobile">
                                        Security
                                    </h1>
                                    <h2 class="title is-size-2 contact-text has-text-centered-mobile">
                                        Report vulnerability
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="columns is-centered is-vcentered contact-us-header-mailbox is-mobile">
                                <div class="column is-narrow">
                                    <ob-cute-robot/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="small-container">
                <div class="content is-medium">
                    <p>
                        At <strong>Open</strong>book, we are committed to security. While we employ secure
                        product development practices in our engineering process, we recognize that not all vulnerabilities may be
                        avoided. We will provide a quick response for all potential vulnerabilities.
                    </p>
                    <p>
                        Please include
                    </p>
                    <ul>
                        <li>
                            A detailed description of the security vulnerability
                        </li>
                        <li>
                            IP addresses, logs and screenshots
                        </li>
                        <li>
                            Instructions on how to reproduce the security vulnerability
                        </li>
                    </ul>
                    <h4>
                        Use one of the following methods to report a potential security vulnerability:
                    </h4>
                    <ul>
                        <li>
                            Send an encrypted email to <a href="mailto:security@open-book.org?subject=Security%20Vulnerability%20Disclosure&body=">security@open-book.org</a> using our PGP key found below. Alternatively, you can retrieve the public PGP key from <a href="https://pgp.mit.edu/pks/lookup?op=get&search=0x92F066DB98D15A45" target="_blank" rel="noopener noreferrer">pgp.mit.edu</a>.
                        </li>
                        <li>
                            Send us a message using <router-link to="contact-us">our contact form</router-link>.
                        </li>
                    </ul>
                    <br/>
                    <p>
                        Thank you for being a cyber-hero!
                    </p>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="small-container">
                <div class="content">
                    <b-collapse class="card" :open.sync="pgpPanelOpen">
                        <div slot="trigger" slot-scope="props" class="card-header">
                            <span class="card-header-title">
                                PGP Key
                            </span>
                            <a class="card-header-icon">
                                <b-icon
                                    pack="fas fa"
                                    :icon="pgpPanelOpen ?  'angle-up' : 'angle-down'">
                                </b-icon>
                            </a>
                        </div>
                        <div class="card-content">
                            <pre>
-----BEGIN PGP PUBLIC KEY BLOCK-----

mQINBFsO6TwBEADVzHsP4gkpmfqWBhsulGBcmA3BApRVigDPvf5InBFR8zYlw4oF
asrXWgA4RdJgU9OGeoo2hy4sZoqI9k8wsWAHIXG5owpNZ3Gm87ko8ZERPg95L28A
MdnOSQom7FITIsrCU3Nwm4kQMl/RIPORAdlynR9+7xsCIruXdnd+dTGTEAoe74ah
LhINobasTBZNCjobtOx5EPw3M0e45LLmNIqPWYlKQ57GyEuMy4B10teqnT3ZPrPS
yEw/3Cav7VT25una++Incuc3+nkq4PP9TNdnQBa9mnNaflbzHqs86L3VG4HhMGlD
OMv7wCIYoULLygXlvakyka00OqxXLY6tTK9y0KM9BYSVpgICDCN+ohohoW/cW5w+
O0SJuNKahNg29EGIWUPP8X921jHX0VkmDZlaI/9DFf6Pdj/x9zbS+FfLDKWAxjqL
4uN0NHXK1uFuZzHPpYSo9pkc89vC48jJ9Zee+XeJARVtxWg9Bdc4UG1ofiMPuvg+
+nhntKBXrzLpQIJNuwQs2eGXMniCoBNEbMghWmcVZOjbwrG6uXD1TaL43Thiglc3
oaTjNeOC9Y7QXmDmkM5/kLnM5/EngR4dfazyil4jKUOnbhzq7nS1CInAj+ICwA5o
tCqYMa5rGLq8wG1CdKlFgpPLVcZENDM0PKNTwvuZLbsuAcLT/ARnSBaoQwARAQAB
tCpPcGVuYm9vayBTZWN1cml0eSA8c2VjdXJpdHlAb3Blbi1ib29rLm9yZz6JAlQE
EwEIAD4WIQQeG81qI+pLFiZPfBSS8GbbmNFaRQUCWw7pPAIbAwUJB4YfgAULCQgH
AgYVCAkKCwIEFgIDAQIeAQIXgAAKCRCS8GbbmNFaRS+fEADOKRcWa213Q41jTt1E
PV5y942P6S5UxTWClKbZnxF+19js7daKk+YpbQpibKSPrTmh8CwnrVbmFBBREC32
yRFzt1hXowLx/aptjG6oRN8GBXkeXZRLfetfjNlZS94i12AiIQrnFaoPu83fNibO
8ruZNgAObMD4HzhnD/TQXD6HfQvctCtx03+ho1S1vsjDNS3RhKSVAFzuf96BRKcY
zR+YTzmQa1oFNFl5pb4F99OSXj+yXBJPoDCHrd1peMv49lTR5CYB+DpBqPjbkx7o
nKDlXlt101d9RL3d3elFIFg+Yje9IRHQ9aw15ofsRZvyABeVeClM7QuFfkbR2hrq
RXm648FKrQFvpZadaUWEydREcQgHyOIAR7RYSiA2DEgJxudOdQWl9ksHPKIU7QrL
GHwk1HJXlmDCsVRbsy/ScGq4lFB1UwIOlyBxNzKEVL2IAu7AdzjRDSB+NlxpY/3O
JQwSxV7SvUKcN7icV+J4smIfBIZcp6jLvwBmYXOas8j1wsHCtg+cHgFrivBvVMGD
2QSoTrroG5dQmcOzPretNDUL1k7OtHq6D6Rmz4YqJ6I+AmRoOWRLIv/34jeDCCCa
A9VyZjyLP0hR83hr6amf6ZgvsMKPvWjMVNeHFugo+rNvb0ESD8Y+scsM312wXgZ7
0adjziKWMjmUrxuhrqocZi4U4LkCDQRbDuk8ARAA0Er03FY4ctGFKbtjOdsWTwSx
0O9vhustsGjDe5aP03ScWjVL/ABRJ8fcJU05VpaOGZU1CaJW5Wk0jqLVljiuOKWY
pq8YsHmJ6SRB5gT+Hvdfntjma8AB5H20eKzjDu4LOeoHjiwrB28W+7Me827JDi0z
/eOnyUvQ61fbOH8Og6kxIK2SKkdtc7r8wcpX6kmLkC8CyPGaSS8gVVUz7z1v7npy
ZlJE3SkYxuIcCuksE8BcKdo8Ez9rfP9z4FKt5JatnfO5N0RCmHvFzdqXfacZgPbm
t2NfE0+I9zNoa6tpRhOKK0fVcPxRYhK0cwHN5Cg7qwWyoX2HxTHqu3mawHZybzrm
L3ulieHFgGycy6lmfcV5+2Vqpfh56x4g2YaIA7REhM2FilMazuG1TEm2Pvdp2OYw
FIGci/X9Z7X3rv7dFIhMY/BueA2GVy9yaidKBb8UvXvnVC+ybipcAtVfyF2vKnHq
NHQTjDSdXjMn1vkCG2b+krbpkpu7eos7BsRAsgzeLc9vU7BDDIsbLhoDmm8KnFnC
3zdMwov6km6M21QEE1TBXxxoC4Kdv7S/ch/z4t4AEl8EdkXjR1RN6DFNHA41zVLj
Uzo5oCmOzynfNnoJS5O2TfWwYG4cIrrWGpOpWuGQbZz+XXm0bLZZePb8weFREOLn
kSFdHK+1jvlQGlOTN88AEQEAAYkCPAQYAQgAJhYhBB4bzWoj6ksWJk98FJLwZtuY
0VpFBQJbDuk8AhsMBQkHhh+AAAoJEJLwZtuY0VpFv60P/A++i3JFFcRbHhjJ/FR2
DKrD3mA7AFIo32KCh3otoqh9n+i2zQp6bY+S17YmAE/AxZmkMmSVa+ExmDx/UKCi
yVHQs3n3BmRt87XJTopAIcrnsSSS09dtqExDFIzltRqGrVVGm5nmcThvEaefCO9d
fnba+8NT7jHzzKLa2vaaUZ1MjvvjMNVbljteObuxT0C2VR3dqyCsZPrFPqs3m7bh
Vm8w5aq5xo7AQCr5g5/AjHKYDnPgiuccQX7gRrARhMDizsJtrlP4bYE7yOLDf+e5
/MzNrALNq0Aq2CFh6zAaUIczLLib4CuBtlRyXw3r7JoktS9cJv1rvMw8KgTZCOik
5fNjVSKBsiHt7jv/BPWnISB5NGXkP7CKaQHfU3eTk8xavChMeHOxBtI9RMTz9QV0
mpHHeCElRF8avw4J/dLuvhgcQH2F95yLD0vo5EGiiQHKPjB9P6ciBnm5cNUOXQSD
uJFTv38ndGlXw+FYNyuapts4K5FDHkQGdoXeIgTVWIpQmT5HBvjJcSCnasdGjSb0
TSA6r0MDujP8aen8o5aud3TDSCXvhofElLw8zYgY/19qbHlgapK+bm59bM0Ttkqy
xokrarEvZ3ZIx2z2p2G0GRPxaMMOB2sQpED/hYzmfQ+m+q49JSaaUoRLv+h5NuTd
x3zuH3Wipi5nwjMTKfTxqI02
=ug3O
-----END PGP PUBLIC KEY BLOCK-----
                    </pre>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </section>
        <section class="hero is-primary is-bold">
            <div class="hero-body has-text-centered">
                <div class="container">
                    <div class="columns is-centered is-vcentered">
                        <div class="column">
                            <h3 class="title is-size-4">
                                Looking for a job?
                            </h3>
                        </div>
                        <div class="column">
                            <router-link to="jobs" class="button is-white is-outlined is-rounded is-medium">Visit our jobs page</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style lang="scss">
    .hero--vulnerability-report{
        position: relative;
        display: flex;
    }
</style>

<script>
    import ObCuteRobot from "../../components/cute-robot.vue";

    export default {
        components: {
            ObCuteRobot
        },
        data(){
            return {
                pgpPanelOpen: false
            }
        }
    }
</script>
