<template>
    <div class="security-robot-container">
        <div class="security-robot"></div>
    </div>
</template>

<style lang="scss">
    // Cute dancing robot courtesy of Carine Bigot
    // https://codepen.io/c4rin3/

    .security-robot-container{
        position: relative;
    }

    .security-robot{
        position: relative;
        display: inline-block;
        width: 240px;
        height: 203px;

        &:before, &:after {
            position: absolute;
            content: '';
            display: block;
            width: 5px;height: 5px;
        }
    }

    .security-robot:before {
        animation: before 0.6s steps(1) infinite;
    }

    @keyframes before { 0% { opacity: 1; } 50% { opacity: 0; }}

    .security-robot:after {
        opacity: 0;
        animation: after 0.6s steps(1) infinite;
    }
    @keyframes after { 0% { opacity: 0; } 50% { opacity: 1; } }

    /*ROBOT*/
    .security-robot:before {
        left: 60px;
        box-shadow:
            /* antenna */
            40px 20px 0 black,
            45px 20px 0 black,
            40px 25px 0 black,
            45px 25px 0 black,
            50px 30px 0 black,
            50px 35px 0 black,
            50px 40px 0 black,
            50px 45px 0 black,
            70px 20px 0 black,
            75px 20px 0 black,
            70px 25px 0 black,
            75px 25px 0 black,
            65px 30px 0 black,
            65px 35px 0 black,
            65px 40px 0 black,
            65px 45px 0 black,
                /*head*/
            40px 50px 0 black,
            45px 50px 0 black,
            50px 50px 0 black,
            55px 50px 0 black,
            60px 50px 0 black,
            65px 50px 0 black,
            70px 50px 0 black,
            75px 50px 0 black,
            35px 55px 0 black,
            35px 60px 0 black,
            35px 65px 0 black,
            35px 70px 0 black,
            35px 75px 0 black,
            35px 80px 0 black,
            80px 55px 0 black,
            80px 60px 0 black,
            80px 65px 0 black,
            80px 70px 0 black,
            80px 75px 0 black,
            80px 80px 0 black,
            40px 85px 0 black,
            45px 85px 0 black,
            50px 85px 0 black,
            55px 85px 0 black,
            60px 85px 0 black,
            65px 85px 0 black,
            70px 85px 0 black,
            75px 85px 0 black,
            30px 60px 0 black,
            30px 65px 0 black,
            30px 70px 0 black,
            25px 65px 0 black,
            85px 60px 0 black,
            85px 65px 0 black,
            85px 70px 0 black,
            90px 65px 0 black,
                /*eyes*/
            50px 60px 0 black,
            50px 65px 0 black,
            50px 70px 0 black,
            65px 60px 0 black,
            65px 65px 0 black,
            65px 70px 0 black,
                /*neck*/
            50px 90px 0 black,
            65px 90px 0 black,
            45px 95px 0 black,
            50px 95px 0 black,
            55px 95px 0 black,
            60px 95px 0 black,
            65px 95px 0 black,
            70px 95px 0 black,
                /*body*/
            40px 100px 0 black,
            45px 100px 0 black,
            50px 100px 0 black,
            55px 100px 0 black,
            60px 100px 0 black,
            65px 100px 0 black,
            70px 100px 0 black,
            75px 100px 0 black,
            35px 105px 0 black,
            35px 110px 0 black,
            35px 115px 0 black,
            35px 120px 0 black,
            35px 125px 0 black,
            35px 130px 0 black,
            35px 135px 0 black,
            35px 140px 0 black,
            35px 145px 0 black,
            80px 105px 0 black,
            80px 110px 0 black,
            80px 115px 0 black,
            80px 120px 0 black,
            80px 125px 0 black,
            80px 130px 0 black,
            80px 135px 0 black,
            80px 140px 0 black,
            80px 145px 0 black,
            40px 150px 0 black,
            45px 150px 0 black,
            50px 150px 0 black,
            55px 150px 0 black,
            60px 150px 0 black,
            65px 150px 0 black,
            70px 150px 0 black,
            75px 150px 0 black,
                /*leds*/
            45px 115px 0 black,
            55px 115px 0 black,
            65px 115px 0 black,
            70px 115px 0 black,
            45px 125px 0 black,
            50px 125px 0 black,
            65px 125px 0 black,
            70px 125px 0 black,
            45px 135px 0 black,
            50px 135px 0 black,
            60px 135px 0 black,
            70px 135px 0 black,
                /*left arm*/
            5px 105px 0 black,
            0px 110px 0 black,
            5px 110px 0 black,
            10px 110px 0 black,
            10px 115px 0 black,
            0px 120px 0 black,
            5px 120px 0 black,
            10px 120px 0 black,
            5px 125px 0 black,
            15px 115px 0 black,
            20px 110px 0 black,
            25px 110px 0 black,
            30px 110px 0 black,
            20px 115px 0 black,
            25px 115px 0 black,
            30px 115px 0 black,
            20px 120px 0 black,
            25px 120px 0 black,
            30px 120px 0 black,
                /*right arm*/
            90px 85px 0 black,
            100px 85px 0 black,
            85px 90px 0 black,
            90px 90px 0 black,
            100px 90px 0 black,
            105px 90px 0 black,
            90px 95px 0 black,
            95px 95px 0 black,
            100px 95px 0 black,
            95px 100px 0 black,
            90px 105px 0 black,
            95px 105px 0 black,
            100px 105px 0 black,
            85px 110px 0 black,
            90px 110px 0 black,
            95px 110px 0 black,
            100px 110px 0 black,
            85px 115px 0 black,
            90px 115px 0 black,
            95px 115px 0 black,
            85px 120px 0 black,
                /*legs*/
            50px 155px 0 black,
            50px 160px 0 black,
            50px 165px 0 black,
            35px 170px 0 black,
            40px 170px 0 black,
            45px 170px 0 black,
            50px 170px 0 black,
            30px 175px 0 black,
            35px 175px 0 black,
            40px 175px 0 black,
            45px 175px 0 black,
            50px 175px 0 black,
            65px 155px 0 black,
            65px 160px 0 black,
            65px 165px 0 black,
            65px 170px 0 black,
            70px 170px 0 black,
            75px 170px 0 black,
            80px 170px 0 black,
            65px 175px 0 black,
            70px 175px 0 black,
            75px 175px 0 black,
            80px 175px 0 black,
            85px 175px 0 black
    }
    .security-robot:after {
        left: 60px;
        box-shadow:
            /* antenna */
            30px 25px 0 black,
            35px 25px 0 black,
            30px 30px 0 black,
            35px 30px 0 black,
            40px 35px 0 black,
            45px 40px 0 black,
            50px 45px 0 black,
            80px 25px 0 black,
            85px 25px 0 black,
            80px 30px 0 black,
            85px 30px 0 black,
            75px 35px 0 black,
            70px 40px 0 black,
            65px 45px 0 black,
            40px 20px 0 black,
            50px 20px 0 black,
            65px 20px 0 black,
            75px 20px 0 black,
            45px 25px 0 black,
            55px 25px 0 black,
            60px 25px 0 black,
            70px 25px 0 black,
                /*head*/
            40px 50px 0 black,
            45px 50px 0 black,
            50px 50px 0 black,
            55px 50px 0 black,
            60px 50px 0 black,
            65px 50px 0 black,
            70px 50px 0 black,
            75px 50px 0 black,
            35px 55px 0 black,
            35px 60px 0 black,
            35px 65px 0 black,
            35px 70px 0 black,
            35px 75px 0 black,
            35px 80px 0 black,
            80px 55px 0 black,
            80px 60px 0 black,
            80px 65px 0 black,
            80px 70px 0 black,
            80px 75px 0 black,
            80px 80px 0 black,
            40px 85px 0 black,
            45px 85px 0 black,
            50px 85px 0 black,
            55px 85px 0 black,
            60px 85px 0 black,
            65px 85px 0 black,
            70px 85px 0 black,
            75px 85px 0 black,
            30px 60px 0 black,
            30px 65px 0 black,
            30px 70px 0 black,
            25px 65px 0 black,
            85px 60px 0 black,
            85px 65px 0 black,
            85px 70px 0 black,
            90px 65px 0 black,
                /*eyes*/
            50px 60px 0 black,
            50px 65px 0 black,
            50px 70px 0 black,
            65px 60px 0 black,
            65px 65px 0 black,
            65px 70px 0 black,
                /*neck*/
            50px 90px 0 black,
            65px 90px 0 black,
            45px 95px 0 black,
            50px 95px 0 black,
            55px 95px 0 black,
            60px 95px 0 black,
            65px 95px 0 black,
            70px 95px 0 black,
                /*body*/
            40px 100px 0 black,
            45px 100px 0 black,
            50px 100px 0 black,
            55px 100px 0 black,
            60px 100px 0 black,
            65px 100px 0 black,
            70px 100px 0 black,
            75px 100px 0 black,
            35px 105px 0 black,
            35px 110px 0 black,
            35px 115px 0 black,
            35px 120px 0 black,
            35px 125px 0 black,
            35px 130px 0 black,
            35px 135px 0 black,
            35px 140px 0 black,
            35px 145px 0 black,
            80px 105px 0 black,
            80px 110px 0 black,
            80px 115px 0 black,
            80px 120px 0 black,
            80px 125px 0 black,
            80px 130px 0 black,
            80px 135px 0 black,
            80px 140px 0 black,
            80px 145px 0 black,
            40px 150px 0 black,
            45px 150px 0 black,
            50px 150px 0 black,
            55px 150px 0 black,
            60px 150px 0 black,
            65px 150px 0 black,
            70px 150px 0 black,
            75px 150px 0 black,
                /*leds*/
            45px 115px 0 black,
            50px 115px 0 black,
            60px 115px 0 black,
            70px 115px 0 black,
            45px 125px 0 black,
            55px 125px 0 black,
            60px 125px 0 black,
            70px 125px 0 black,
            45px 135px 0 black,
            55px 135px 0 black,
            65px 135px 0 black,
            70px 135px 0 black,
                /*left arm*/
            15px 85px 0 black,
            25px 85px 0 black,
            10px 90px 0 black,
            15px 90px 0 black,
            25px 90px 0 black,
            30px 90px 0 black,
            15px 95px 0 black,
            20px 95px 0 black,
            25px 95px 0 black,
            20px 100px 0 black,
            15px 105px 0 black,
            20px 105px 0 black,
            25px 105px 0 black,
            15px 110px 0 black,
            20px 110px 0 black,
            25px 110px 0 black,
            30px 110px 0 black,
            20px 115px 0 black,
            25px 115px 0 black,
            30px 115px 0 black,
            30px 120px 0 black,
                /*right arm*/
            110px 105px 0 black,
            105px 110px 0 black,
            110px 110px 0 black,
            115px 110px 0 black,
            100px 115px 0 black,
            105px 115px 0 black,
            105px 120px 0 black,
            110px 120px 0 black,
            115px 120px 0 black,
            110px 125px 0 black,
            85px 110px 0 black,
            90px 110px 0 black,
            95px 110px 0 black,
            85px 115px 0 black,
            90px 115px 0 black,
            95px 115px 0 black,
            85px 120px 0 black,
            90px 120px 0 black,
            95px 120px 0 black,
                /*legs*/
            50px 155px 0 black,
            50px 160px 0 black,
            50px 165px 0 black,
            35px 170px 0 black,
            40px 170px 0 black,
            45px 170px 0 black,
            50px 170px 0 black,
            30px 175px 0 black,
            35px 175px 0 black,
            40px 175px 0 black,
            45px 175px 0 black,
            50px 175px 0 black,
            65px 155px 0 black,
            65px 160px 0 black,
            65px 165px 0 black,
            65px 170px 0 black,
            70px 170px 0 black,
            75px 170px 0 black,
            80px 170px 0 black,
            65px 175px 0 black,
            70px 175px 0 black,
            75px 175px 0 black,
            80px 175px 0 black,
            85px 175px 0 black
    }
</style>

<script>
    export default{
        name: 'ob-cute-robot'
    }
</script>
